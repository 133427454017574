import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';
import CategoryBanner from '../components/category-banner/category-banner';
import ProductGrid from '../components/product-grid/product-grid';

const CategoryPage = ({ data }: any) => {
	const { categoryName, childrenErpItem } = data.erpCategory;
	let image = null;
	if(data.prismicCategory!=null){
		image = data.prismicCategory?.data?.image
	}

	return (
		<PrimaryLayout
			fluid={true}
			showNoticeBar={false}
		>
			<SEO title={categoryName} />
			<CategoryBanner bgImage={image} title={categoryName} />
			<ProductGrid
				id="category"
				products={childrenErpItem}
				withLink={true}
				isCategoryProduct={true}
			/>
		</PrimaryLayout>
	);
};

export const query = graphql`
	query($slug: String!) {
		erpCategory(slug: { eq: $slug }) {
			categoryName
			slug
			childrenErpItem {
				id
				item_name
				slug
				last_purchase_rate
				creation
				item_code
				is_sales_item
				localImage {
					childImageSharp {
						gatsbyImageData(
							aspectRatio: 1.25,
							placeholder: TRACED_SVG,
							transformOptions: {fit: CONTAIN},
							backgroundColor: "transparent"
						)
					}
				}
			}
		}
		prismicCategory(uid: {eq: $slug}) {
		  uid
		  data {
			image {
			  fluid {
				...GatsbyPrismicImageFluid_withWebp_noBase64
			  }
			}
		  }
		}
	}
`;

export default CategoryPage;
